<template>
    <SimpleTable formclass="components/form/Board" endpoint="api/v2/board" :aditionalColumns="headers">
        <template v-slot="data">
            <h1>Gateway</h1>
            <v-divider class="mb-4" />
            <v-layout wrap class="my-12">
                <v-flex xs12>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ total(data.items) }}
                            </div>
                            Cadastradas
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";

export default {
    components: { SimpleTable },

    data() {
        return {
            headers: [
                { text: "Conexão", value: "connection", align: "right", sortable: false },
                { text: "Versão", value: "version", align: "right", sortable: true },
                { text: "Identificador", value: "name", align: "right", sortable: true },
            ],
        };
    },

    methods: {
        total(items) {
            return Number.parseFloat(items.length).toFixed(0);
        },
    },
};
</script>